import { Type } from './Type'

export function getType(type: Type): string {
  switch (type) {
    case 'restaurant':
      return 'Restaurant'
    case 'business':
      return 'Geschäft'
    case 'rathausplatz':
      return 'Rathausplatz'
    case 'chef':
      return 'Koch'
    default:
      throw new Error(`Unknown type: ${type}`)
  }
}
