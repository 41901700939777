import classNames from 'classnames'

import type { Options } from './types'
import { block } from '~/base'

import * as styles from './Block.css'

type Props = React.ComponentPropsWithoutRef<'div'> & {
  options?: Options
}

export function Block({ children, options }: Props) {
  return (
    <div
      className={classNames([
        options?.background && styles.withBackground,
        block({
          margin: options?.margin || undefined,
          padding: options?.padding || undefined,
          withBackground: options?.background || undefined,
        }),
      ])}
    >
      {children}
    </div>
  )
}
