import { useState } from 'react'
import * as styles from './YouTubeVideo.css'
import Image from 'next/image'
import { getVideoIdFromUrl } from './getVideoIdFromUrl'
import { Image as ImageType } from '~/lib/storyblok'
import classNames from 'classnames'

type Props = {
  url: string
  previewImage?: ImageType
  playButtonSize?: 'small' | 'medium'
}

export function YouTubeVideo({ url, previewImage, playButtonSize = 'medium' }: Props) {
  const [showIframe, setShowIframe] = useState(false)
  const id = getVideoIdFromUrl(url)

  const onShowIframe = () => {
    if (!showIframe) {
      setShowIframe(true)
    }
  }

  return (
    <div className={styles.wrapper} onClick={onShowIframe}>
      {showIframe ? (
        <iframe
          title="Video"
          width="560"
          height="315"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          src={`https://www.youtube.com/embed/${id}?autoplay=1&state=1`}
          className={styles.iframe}
        />
      ) : (
        <div className={styles.overlay}>
          {previewImage ? (
            <Image
              src={previewImage.url}
              width={previewImage.width}
              height={previewImage.height}
              alt=""
              className={styles.image}
            />
          ) : (
            <Image
              src={`https://i.ytimg.com/vi/${id}/hqdefault.jpg`}
              width={1280}
              height={720}
              alt=""
              className={styles.image}
            />
          )}
          <button className={styles.button} aria-label="Start Video">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 148 148"
              className={classNames([styles.buttonIcon, playButtonSize === 'small' && styles.buttonIconSmall])}
            >
              <path
                d="M74,148a73.654,73.654,0,0,1-41.374-12.638A74.216,74.216,0,0,1,5.815,102.8a73.9,73.9,0,0,1,6.823-70.178A74.216,74.216,0,0,1,45.2,5.815a73.9,73.9,0,0,1,70.178,6.823A74.216,74.216,0,0,1,142.184,45.2a73.9,73.9,0,0,1-6.823,70.178A74.216,74.216,0,0,1,102.8,142.184,73.536,73.536,0,0,1,74,148ZM62.756,50.283h0a5.084,5.084,0,0,0-5.081,5.087V92.632A5.082,5.082,0,0,0,65.4,96.973L95.89,78.342a5.086,5.086,0,0,0,0-8.68L65.4,51.031a5.052,5.052,0,0,0-2.646-.747Z"
                fill="#fff"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  )
}
