import { useRef } from 'react'
import classNames from 'classnames'
import { useInView } from 'framer-motion'

import * as styles from './Title.css'

type Props = React.ComponentPropsWithoutRef<'h1'> & {
  hyphens?: boolean
  animation?: boolean
  shadow?: boolean
  shadowStrong?: boolean
}

export function Title({ children, shadow = false, shadowStrong = false, hyphens = true, animation = false }: Props) {
  const ref = useRef(null)
  const isInView = useInView(ref)

  return (
    <h1
      className={classNames([
        styles.title,
        hyphens && styles.withHyphens,
        animation && styles.titleAnimation,
        animation && isInView && styles.titleAnimationActive,
        shadow && styles.withShadow,
        shadowStrong && styles.withShadowStrong,
      ])}
      ref={ref}
    >
      {children}
    </h1>
  )
}
