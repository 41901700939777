import { useRouter } from 'next/router'
import classNames from 'classnames'

import * as styles from './BackButton.css'

type Props = React.ComponentPropsWithoutRef<'button'> & {}

export function BackButton({ children, className, ...props }: Props) {
  const router = useRouter()

  function onBack() {
    router.back()
  }

  return (
    <button {...props} className={classNames([styles.button, className])} onClick={onBack}>
      <span className={styles.arrow}>←</span> {children}
    </button>
  )
}
