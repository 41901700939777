import Image from 'next/image'
import { motion } from 'framer-motion'

import { Heading } from '~/components'
import { IndicatorIcon } from './IndicatorIcon'

import * as styles from './Hero.css'

import teaser from './assets/hero_neu.jpg'

type Props = {
  minimal?: boolean
}

export function Hero({ minimal }: Props) {
  function onClickMore() {
    document.querySelector('#main')?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className={minimal ? styles.heroMinimal : styles.hero}>
      <div className={styles.container}>
        <motion.div
          className={styles.main}
          initial={{ opacity: 0, y: 48 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, type: 'spring', stiffness: 600, damping: 100 }}
        >
          <Heading variant="title" className={styles.heading}>
            <span>1. — 11.</span>
            <br /> September
            <br /> in Vaduz
          </Heading>
        </motion.div>
        {!minimal && (
          <motion.button
            className={styles.indicator}
            onClick={onClickMore}
            initial={{ opacity: 0, y: 48 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2, type: 'spring', stiffness: 600, damping: 100 }}
          >
            <IndicatorIcon className={styles.indicatorIcon} /> mehr erfahren
          </motion.button>
        )}
      </div>
      <motion.div
        animate={{ scale: 1.1 }}
        transition={{ type: 'spring', stiffness: 600, damping: 100 }}
        className={styles.canvas}
      >
        <Image
          src={teaser}
          alt="Genussfestival Vaduz"
          layout="fill"
          objectFit="cover"
          placeholder="blur"
          objectPosition={`36%`}
        />
      </motion.div>
    </div>
  )
}
