import * as styles from './Box.css'

type Props = React.ComponentPropsWithoutRef<'div'>

export function Box({ children }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}
