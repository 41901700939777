import Image from 'next/image'
import classNames from 'classnames'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import * as styles from './Gallery.css'

type Props = {
  alternative?: boolean
  images: any[]
}

const imagesVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
    },
  },
}

const imageVariants = {
  hidden: {
    opacity: 0,
    y: 60,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 600, damping: 100 },
  },
}

export function Gallery({ alternative, images }: Props) {
  const [ref, inView] = useInView({
    rootMargin: '-10px 0px -90px 0px',
  })

  if (!images) {
    return null
  }

  return (
    <motion.div
      className={classNames([styles.gallery, alternative && styles.galleryAlternative])}
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
    >
      <motion.ul className={styles.list} variants={imagesVariants}>
        {images.map((image, index) => (
          <motion.li className={styles.item} key={index} variants={imageVariants}>
            <Image
              src={image}
              className={styles.image}
              width={image.width}
              height={image.height}
              alt=""
              placeholder="blur"
            />
          </motion.li>
        ))}
      </motion.ul>
    </motion.div>
  )
}
