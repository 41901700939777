export function getVideoIdFromUrl(url: string) {
  if (url.includes('https://youtu.be')) {
    const end = url.substring(url.lastIndexOf('/') + 1)
    const id = end.substring(0, end.lastIndexOf('?'))
    return id
  }

  if (url.includes('https://www.youtube.com')) {
    const id = url.substring(url.lastIndexOf('v=') + 2)
    return id
  }
}
