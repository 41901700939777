import classNames from 'classnames'
import NextLink, { LinkProps } from 'next/link'

import * as styles from './Link.css'

type Props = { color?: boolean } & LinkProps &
  React.ComponentPropsWithoutRef<'a'> & {
    children: React.ReactNode
    color?: boolean
  } & any

export function Link({ href, children, className, color, ...props }: Props) {
  return (
    <NextLink href={href} passHref legacyBehavior>
      <a {...props} className={classNames([color && styles.color, className])}>
        {children}
      </a>
    </NextLink>
  )
}
