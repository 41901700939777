import { useState } from 'react'
import Image from 'next/image'
import classNames from 'classnames'

import { Link, Button } from '~/components'
import type { Image as ImageType } from '~/lib/storyblok'
import { getType, Type } from '~/storyblok/datasources'

import * as styles from './OverviewBox.css'

type Props = React.ComponentPropsWithoutRef<'div'> & {
  title: string
  description?: string | null
  button?: string
  image: ImageType
  href: string
  type?: Type
  aspectRatio?: boolean
  shadow?: boolean
  target?: string
}

export function OverviewBox({
  title,
  description,
  image,
  href,
  button,
  type,
  aspectRatio,
  className,
  shadow,
  target,
  ...props
}: Props) {
  const [isActive, setIsActive] = useState(false)

  return (
    <Link
      href={href}
      className={classNames([styles.event, className])}
      target={target}
      {...props}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div className={styles.media}>
        <Image src={image.url} alt={image.alt} layout="fill" objectFit="cover" />
      </div>
      <div className={styles.main}>
        <h2 className={classNames(styles.title, shadow && styles.titleWithShadow)}>{title}</h2>
        {description && <div className={classNames(styles.text, shadow && styles.titleWithShadow)}>{description}</div>}
        <div className={styles.action}>
          <Button variant="primary" active={isActive}>
            {type ? `Zum ${getType(type)}` : button || 'Mehr'}
          </Button>
        </div>
      </div>
    </Link>
  )
}
