import classNames from 'classnames'
import { render } from 'storyblok-rich-text-react-renderer'

import { RichText } from '~/lib/storyblok'

import * as styles from './RichText.css'

// TODO: Typings. See https://www.npmjs.com/package/storyblok-rich-text-react-renderer
type Props = React.ComponentPropsWithoutRef<'div'> & {
  content: RichText
  resolvers?: any
}

export function RichText({ className, content, ...props }: Props) {
  return (
    <div className={classNames([styles.wrapper, className])} {...props}>
      {render(content)}
    </div>
  )
}
