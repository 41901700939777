import classNames from 'classnames'
import { font, FontVariants } from '~/base'

import * as styles from './Text.css'

type Props = React.ComponentPropsWithoutRef<'div'> & FontVariants

export function Text({ variant, children }: Props) {
  return <div className={classNames([styles.text, font({ variant })])}>{children}</div>
}
