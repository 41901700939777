import { useEffect } from 'react'

export function useClickOutside(ref: any, handler: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (!ref.current || ref.current.contains(event.target) || event.target.id === 'menu-button') {
        return
      }

      handler(event)
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, handler])
}
