import classNames from 'classnames'
import { font, FontVariants } from '~/base'

import * as styles from './Heading.css'

type Props = React.ComponentPropsWithoutRef<'h2'> &
  FontVariants & {
    margin?: 'small' | 'medium' | 'large' // TODO: use type options from styles.marginVariants
    as?: any
    level?: 'h1' | 'h2'
  }

export function Heading({ level = 'h1', className, margin, children, as: Component = 'h1' }: Props) {
  return (
    <Component
      className={classNames([
        styles.heading,
        margin && styles.marginVariants[margin],
        level === 'h2' && styles.heading2,
        className,
      ])}
    >
      <span>{children}</span>
    </Component>
  )
}
